import React from 'react';
import NewsletterTemplate from 'templates/newsletter';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Content = () => {
  const { clickmaps, userexperience, formanalytics, features } = useStaticQuery(graphql`
    query {
      clickmaps: file(relativePath: { eq: "blog/clickmaps1.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      formanalytics: file(relativePath: { eq: "tutorials/formanalytics.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      userexperience: file(relativePath: { eq: "user-experience/cover/userexperience1.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      features: file(relativePath: { eq: "blog/data-analytics/cover.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
      <tbody>
        <tr>
          <td>
            <table className="text" cellSpacing="0" cellPadding="0" style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ padding: '40px 40px 12px' }}>
                    <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                      Here’s some fresh LiveSession news!
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 40px' }}>
                    <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                      Clickmaps, guides and some spoiler about our next feature are here for you in
                      today&apos;s newsletter.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 0 34px' }}>
                    <div style={{ borderTop: '1px solid #ddd' }} />
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0 40px' }}>
                    <h2 style={{ margin: 0, fontSize: '22px' }}>Product updates</h2>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '32px 40px 12px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              href="/blog/clickmaps/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: 'block' }}
                            >
                              <Img
                                fluid={clickmaps.childImageSharp.fluid}
                                alt="Product update - Clickmaps"
                                style={{
                                  border: 0,
                                  lineHeight: '100%',
                                  outline: 0,
                                  display: 'block',
                                  height: 'auto',
                                  borderRadius: '6px',
                                  maxWidth: '100%',
                                }}
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a
                              href="/blog/clickmaps/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: 'none',
                                color: '#061631',
                                fontWeight: 800,
                                marginTop: '24px',
                                display: 'block',
                              }}
                            >
                              Clickmaps BETA
                            </a>
                            <p
                              style={{
                                color: '#9b9b9b',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Clickmap BETA is an accurate way to collect information on user
                              behavior, where{' '}
                              <strong>
                                you&apos;ll see which areas of your page were clicked the most
                              </strong>
                              . This info is sure to help make your LiveSession experience even
                              better!
                            </p>
                            <p
                              style={{
                                color: '#9b9b9b',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              To find Clickmaps BETA, go to any recording and on the top, you’ll see
                              the “CLICKMAP” tab.
                            </p>
                            <a
                              href="/blog/clickmaps/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                fontSize: '16px',
                                color: '#0446CA',
                                fontWeight: 700,
                                textDecoration: 'none',
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Try the new Clickmaps BETA
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '34px 0' }}>
                    <div style={{ borderTop: '1px solid #ddd' }} />
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0 40px' }}>
                    <h2 style={{ margin: 0, fontSize: '22px' }}>LiveSession Tutorials</h2>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '32px 40px 12px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              href="/tutorials/form-analytics/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: 'block' }}
                            >
                              <Img
                                fluid={formanalytics.childImageSharp.fluid}
                                alt="Content update - Form Analytics"
                                style={{
                                  border: 0,
                                  lineHeight: '100%',
                                  outline: 0,
                                  display: 'block',
                                  height: 'auto',
                                  borderRadius: '6px',
                                  maxWidth: '100%',
                                }}
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a
                              href="/tutorials/form-analytics/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: 'none',
                                color: '#061631',
                                fontWeight: 800,
                                marginTop: '24px',
                                display: 'block',
                              }}
                            >
                              Form analytics tutorial
                            </a>
                            <p
                              style={{
                                color: '#9b9b9b',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Online form analytics has been proven to successfully boost lead
                              generation. How? LiveSession’s tutorial will surely help you out!
                            </p>
                            <a
                              href="/tutorials/form-analytics/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                fontSize: '16px',
                                color: '#0446CA',
                                fontWeight: 700,
                                textDecoration: 'none',
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Read the tutorial
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '34px 0' }}>
                    <div style={{ borderTop: '1px solid #ddd' }} />
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0 40px' }}>
                    <h2 style={{ margin: 0, fontSize: '22px' }}>LiveSession Guides</h2>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '32px 40px 12px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              href="/user-experience/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: 'block' }}
                            >
                              <Img
                                fluid={userexperience.childImageSharp.fluid}
                                alt="Content update - Form Analytics"
                                style={{
                                  border: 0,
                                  lineHeight: '100%',
                                  outline: 0,
                                  display: 'block',
                                  height: 'auto',
                                  borderRadius: '6px',
                                  maxWidth: '100%',
                                }}
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a
                              href="/user-experience/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: 'none',
                                color: '#061631',
                                fontWeight: 800,
                                marginTop: '24px',
                                display: 'block',
                              }}
                            >
                              UX Guide
                            </a>
                            <p
                              style={{
                                color: '#9b9b9b',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Following the release of our previous guide on usability testing,
                              we’ve decided to describe all aspects of User Experience (UX).
                            </p>
                            <p
                              style={{
                                color: '#9b9b9b',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Containing chapters upon chapters of information to build your
                              knowledge, such as:
                            </p>
                            <ul
                              style={{
                                color: '#9b9b9b',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                                paddingLeft: 20,
                              }}
                            >
                              <li>managing and implementing a successful UX research</li>
                              <li>proper UX design strategies</li>
                              <li>creating a journey map</li>
                              <li>...and many more!</li>
                            </ul>

                            <a
                              href="/user-experience/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                fontSize: '16px',
                                color: '#0446CA',
                                fontWeight: 700,
                                textDecoration: 'none',
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Discover the UX Guide
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '34px 0' }}>
                    <div style={{ borderTop: '1px solid #ddd' }} />
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0 40px' }}>
                    <h2 style={{ margin: 0, fontSize: '22px' }}>Blog posts</h2>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '32px 40px 12px' }}>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              href="/blog/how-to-use-data-analytics-to-improve-customer-support/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: 'block' }}
                            >
                              <Img
                                fluid={features.childImageSharp.fluid}
                                alt="Blog post - How to Use Data Analytics to Improve Customer Support"
                                style={{
                                  border: 0,
                                  lineHeight: '100%',
                                  outline: 0,
                                  display: 'block',
                                  height: 'auto',
                                  borderRadius: '6px',
                                  maxWidth: '100%',
                                }}
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a
                              href="/blog/how-to-use-data-analytics-to-improve-customer-support/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: 'none',
                                color: '#061631',
                                fontWeight: 800,
                                marginTop: '24px',
                                display: 'block',
                              }}
                            >
                              How to Use Data Analytics to Improve Customer Support
                            </a>
                            <p
                              style={{
                                color: '#9b9b9b',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Exceptional customer service is a significant element that often
                              determines whether we want to do business with someone or not. Learn
                              how to improve your customer support efforts using data analytics.
                            </p>
                            <a
                              href="/blog/how-to-use-data-analytics-to-improve-customer-support/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                fontSize: '16px',
                                color: '#0446CA',
                                fontWeight: 700,
                                textDecoration: 'none',
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Learn about improving customer support
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '34px 0' }}>
                    <div style={{ borderTop: '1px solid #ddd' }} />
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0 40px' }}>
                    <h2 style={{ margin: 0, fontSize: '22px' }}>What to expect in the future</h2>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '32px 40px 12px' }}>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src="https://emails.livesession.io/dev-tools.png"
                              alt="Blog post - How to Use Data Analytics to Improve Customer Support"
                              style={{
                                border: 0,
                                lineHeight: '100%',
                                outline: 0,
                                display: 'block',
                                height: 'auto',
                                borderRadius: '6px',
                                maxWidth: '100%',
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p
                              style={{
                                textDecoration: 'none',
                                color: '#000',
                                fontWeight: 800,
                                marginTop: '24px',
                                display: 'block',
                                fontSize: 18,
                              }}
                            >
                              Developer Tools
                            </p>
                            <p
                              style={{
                                color: '#9b9b9b',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              <strong>LiveSession Developer Tools</strong> - a tool for developers
                              that allows you to catch JavaScript errors, console logs, or page
                              loading speed for each user session. Stay tuned!
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '40px 60px 12px 40px' }}>
                    <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                      Want to get more precise information on our news, feel free to reply to this
                      email. We’re here to answer all your questions!
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '24px 40px 32px' }}>
                    <table className="kamil" cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr style={{ verticalAlign: 'middle' }}>
                          <th>
                            <img
                              src="https://emails.livesession.io/kamil-rounded.png"
                              className="kamil-img"
                              style={{ maxWidth: '56px' }}
                              alt="kamil"
                            />
                          </th>
                          <th style={{ paddingLeft: '16px' }}>
                            <p
                              className="info"
                              style={{
                                margin: 0,
                                textAlign: 'left',
                                fontWeight: 400,
                                lineHeight: '140%',
                              }}
                            >
                              <strong>Kamil</strong> <br />
                              CEO and Founder @ LiveSession
                            </p>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const Newsletter = NewsletterTemplate(Content);

export const frontmatter = {
  title: 'LiveSession Newsletter September 2020',
  description: "Read LiveSession's monthly update from September 2020.",
  date: '2020-09-01',
  link: '/newsletters/september-2020/',
};

export default () => <Newsletter {...frontmatter} />;
